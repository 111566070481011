<template>
	<div>
		<social-sharing
			:url="url"
			:title="title"
			:description="$t('SHARE.HINT')"
			:quote="quote"
			:hashtags="hashtags || 'thefireworksblog'"
			inline-template>
		<div>
			{{ $t("SHARE.TITLE") }}:
			<network network="facebook" class="social-sharing">
				Facebook
			</network>
			<network network="pinterest" class="social-sharing">
				Pinterest
			</network>
			<network network="reddit" class="social-sharing">
				Reddit
			</network>
			<network network="twitter" class="social-sharing">
				Twitter
			</network>
			<network network="whatsapp" class="social-sharing">
				Whatsapp
			</network>
		</div>
	</social-sharing>
</div>
</template>
<script>
	export default {
		props: {
			url: String,
			title: String,
			description: String,
			quote: String,
			hashtags: String
		},
		name: 'ShareBox'
	}
</script>
<style>
	.social-sharing {
		cursor: pointer;
		color: #f27d0c;
	}
</style>