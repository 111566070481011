<template>
  <div class="post post-content">
    <h1>{{ post.title }}</h1>
    <small>{{ post.author.name }} - {{ post.createdAt | formatLongDate }}</small>
    <div v-html="post.content"></div>
    <TagsList v-if="post.tags && post.tags.length" :tags="post.tags"></TagsList>
    <button v-if="hasShare" @click="shareBlog(post.title)">{{ $t("SHARE.TITLE") }}</button>
    <ShareBox v-else :url="getPostUrl()" :title="post.title" :hashtags="(post.tags || []).join(',')"></ShareBox>

    <Related v-if="related" :posts="related"></Related>

    <Comments :post="post"></Comments>

    <a href="javascript:void(0)" title="Go Back" onclick="window.history.back()">&larr;{{ $t("GLOBAL.BACK") }}</a>
  </div>
</template>
<script>
  import BlogService from '@/services/blog-service'
  import ShareBox from '@/components/ShareBox.vue'
  import TagsList from '@/components/TagsList.vue'
  import Comments from '@/components/Comments.vue'
  import Related from '@/components/Related.vue'

  export default {
    name: 'Post',
    components: {
      ShareBox,
      TagsList,
      Comments,
      Related
    },
    data() {
      return {
        hasShare: navigator.share,
        post: {
          author: {}
        },
        related: [],
        defaultMetaDescription: 'Talking about the interesting Fireworks Culture, Art, Science and Technique around the World. 花火'
      }
    },
    watch: {
      '$route': function(){
        const { slug } = this.$route.params;
        this.loadData(slug);
      }
    },
    mounted() {
      const { slug } = this.$route.params;
      this.loadData(slug);
    },
    methods: {
      loadData(slug) {
        BlogService.detailBySlug(slug).then(resp => {
          this.post = resp;
          document.title = `The fireworks blog | ${resp.title}`;
          document.querySelector('meta[name="description"]').setAttribute(
            "content",
            `${this.post.preview} | ${this.defaultMetaDescription}`
          )
          this.getRelated(resp._id);
        })
      },
      getRelated(id) {
        BlogService.related(id).then(related => {
          this.related = related;
        })
      },
      shareBlog(title) {
        if (navigator.share) {
          navigator.share({
            title: 'Read this Post on The Fireworks Blog',
            text: title,
            url: window.location.href,
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
      },
      getPostUrl() {
        return window.location.href
      }
    }
  }
</script>
<style lang="scss">
  .post {
    img {
      max-width: 100% !important;
      height: auto !important;
    }
  }
</style>