<template>
	<div>
		<h1>Create survey</h1>
		<form @submit.prevent="save">
			<label for="title">Title</label>
			<input type="text" name="title" placeholder="Title" v-model="survey.title" required>
			<label for="description">Description</label>
			<textarea name="description" id="description" cols="30" rows="5" v-model="survey.description" required></textarea>

			<div v-for="(item, index) in survey.questions" :key="index">
				<input type="text" v-model="item.text" placeholder="Text">
				<select name="answerType" v-model="item.answerType">
					<option value="text">Text</option>
					<option value="radio">Radio</option>
					<option value="checkbox">Checkbox</option>
					<option value="select">Select</option>
				</select>

				<div v-if="['radio', 'select', 'checkbox'].indexOf(item.answerType) > -1">
					<button type="button" @click="addOption(item)">Add option</button>
					<div v-for="(option, idx) in item.answerOptions" :key="idx">
						<input type="text" v-model="option.text" placeholder="Answer option">
						<input type="text" v-model="option.value" placeholder="Answer value">
					</div>
				</div>
			</div>

			<label for="startDate">Start date</label>
			<input type="date" name="startDate" v-model="survey.startDate" placeholder="Start">
			<label for="finishDate">Finish date</label>
			<input type="date" name="finishDate" v-model="survey.finishDate" placeholder="Finish">

			<button type="button" @click="addQuestion">Add question</button>
			<button type="submit">Save</button>
		</form>
	</div>
</template>
<script>
	import SurveyService from "@/services/survey-service";

	export default {
		name: "SurveyForm",
		data() {
			return {
				survey: {
					title: "",
					description: "",
					startDate: new Date(),
					finishDate: new Date(),
					questions: [],
					answers: []
				}
			}
		},
		mounted() {

		},
		methods: {
			save() {
				SurveyService.create(this.survey).then(resp => {
					console.log("created survey ", resp);
				})
			},
			addQuestion() {
				this.survey.questions.push({
					text: "",
					answerType: "",
					answerOptions: []
				})
			},
			addOption(question) {
				if (! question.answerOptions) {
					question.answerOptions = []
				}

				question.answerOptions.push({
					text: "",
					value: ""
				})
			}
		}
	}
</script>
<style scoped lang="scss">
	form {
		display: flex;
		flex-direction: column;
	}
</style>