import ApiClient from './api-client';

export default class SurveyService {
	static create(data) {
		return ApiClient.apiPost('survey', data)
	}

	static getAll() {
		return ApiClient.apiGet('survey')
	}

	static getBySlug(slug) {
		return ApiClient.apiGet(`survey/${slug}`)
	}

	static answer(id, data) {
		return ApiClient.apiPost(`survey/${id}/answer`, data)
	}
}