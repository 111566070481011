<template>
	<div class="tags-list">
		Tags:
		<router-link v-for="tag in tags" :key="tag" :to="'/?tag='+tag" v-on:click.native="onTagClicked">#{{tag}}</router-link>
	</div>
</template>
<script>
	export default {
		name: 'TagsList',
		props: {
			tags: null
		},
		methods: {
			onTagClicked() {
				this.$emit('onTagClicked')
			}
		}
	}
</script>
<style lang="scss">
	.tags-list {
		margin-bottom: 10px;
		a {
			margin-right: 5px;
		}
	}
</style>