import Vue from 'vue'
import { EventBus } from '../event-bus.js'
import Helpers from '@/helpers/helpers.js'
//import ToastService from './toast-service'

export default class ApiClient {
  static callAPI (method, url, data, showLoading = true) {

    let currentLang = localStorage.getItem('userLang')
    url = Helpers.updateQueryStringParameter(url, 'lang', currentLang || 'en')

    const options = {
      method: method,
      url: `${process.env.VUE_APP_API_URL}/api/${url}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    let localUser = localStorage.getItem('currentUser')

    if (localUser) {
      localUser = JSON.parse(localUser)
      if (localUser.token) {
        options.headers['Authorization'] = `Bearer ${localUser.token}`
      }
    }

    if (data) {
      options.body = data
    }

    EventBus.$emit('httpStart', Object.assign({}, options, {showLoading}))
    EventBus.$emit('loading:show')
    return new Promise((resolve, reject) => {
      Vue.http(options).then(resp => {
        EventBus.$emit('httpEnd', resp)
        EventBus.$emit('loading:hide')
        resolve(resp.body)
      }).catch(err => {
        EventBus.$emit('httpError', err)
        EventBus.$emit('loading:hide')
        console.log('http err -> ', err)
        if (err.status === 0) {
          //ToastService.show(`Ocorreu um erro: Verifique se tem uma conexão com a Internet!`)
        } else {
          //ToastService.show(`Ocorreu um erro: ${err.message || err.statusText || err.bodyText || err.body || err.status}`)
        }

        reject(err)
      })
    });
  }
  static apiGet (url, showLoading = true) {
    return this.callAPI('GET', url, null, showLoading)
  }
  static apiPost (url, data, showLoading = true) {
    return this.callAPI('POST', url, data, showLoading)
  }
  static apiPut (url, data, showLoading = true) {
    return this.callAPI('PUT', url, data, showLoading)
  }
  static apiDelete (url, showLoading = true) {
    return this.callAPI('DELETE', url, null, showLoading)
  }
}
