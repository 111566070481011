<template>
	<div class="related">
		<h2>{{ $t("POST.RELATED") }}</h2>
		<router-link tag="div" v-for="post in posts" :key="post._id" :to="{ name: 'Post', params: { slug: post.slug }}" class="related-item">
			<div class="related-header">
				<h3>{{ post.title }}</h3>
			</div>
			<div class="related-content">
				<img v-if="post.previewImage" :src="post.previewImage" alt="">
				<p>
					{{ post.preview }}
				</p>
			</div>
		</router-link>
	</div>
</template>
<script>
	import BlogService from "@/services/blog-service"

	export default {
		name: "Related",
		props: ["posts"]
	}
</script>
<style lang="scss" scoped>
	.related {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.related-item {
		max-width: 200px;
	}

	.related-content {
		img {
			max-height: 100px;
		}
	}
</style>
