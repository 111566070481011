import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PostForm from '../views/PostForm.vue'
import Post from '../views/Post.vue'
import Misc from '../views/Misc.vue'
import SurveyForm from '../views/SurveyForm.vue'
import Surveys from '../views/Surveys.vue'
import Survey from '../views/Survey.vue'
import UserService from '@/services/user-service'
import Events from '../views/Events.vue'
import Files from '../views/Files.vue'
import File from '../views/File.vue'
import CryptoDonate from '../views/CryptoDonate.vue'
import Subscribe from '../views/Subscribe.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/post-form/:id?',
    name: 'PostForm',
    component: PostForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/post/:slug',
    name: 'Post',
    component: Post
  },
  {
    path: '/misc',
    name: 'Misc',
    component: Misc
  },
  {
    path: '/misc/:slug',
    name: 'Misc',
    component: Post
  },
  {
    path: '/survey-form',
    name: 'SurveyForm',
    component: SurveyForm
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: Surveys
  },
  {
    path: '/survey/:slug',
    name: 'Survey',
    component: Survey
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/files',
    name: 'Files',
    component: Files
  },
  {
    path: '/file/:slug',
    name: 'File Details',
    component: File
  },
  {
    path: '/donate',
    name: 'Donate',
    component: CryptoDonate
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth) {
    if (UserService.isAuthenticated()) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
