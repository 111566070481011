import ApiClient from './api-client';
import qs from "query-string";

export default class FileService {
	static create(file) {
		return ApiClient.apiPost('file', file);
	}

	static update(file) {
		return ApiClient.apiPut(`file/${file._id}`, file)
	}

	static list(query) {
		const q = {};

		if (query.search) {
			q.search = query.search
		}

		if (query.page) {
			q.page = query.page;
		}

		return ApiClient.apiGet(`file?${qs.stringify(q)}`)
	}

	static detailBySlug(slug) {
		return ApiClient.apiGet(`file/detail/${slug}`)
	}

	static delete(id) {
		return ApiClient.apiDelete(`file/${id}`)
	}
}