import ApiClient from './api-client';
import qs from "query-string";

export default class EventService {
	static create(post) {
		return ApiClient.apiPost('event', post);
	}

	static update(post) {
		return ApiClient.apiPut(`event/${post._id}`, post)
	}

	static list(query) {
		const q = {};
		if (query.tag) {
			q.tag = query.tag
		}

		if (query.search) {
			q.search = query.search
		}

		return ApiClient.apiGet(`event?${qs.stringify(q)}`)
	}

	static getMine() {
		return ApiClient.apiGet('event-me')
	}

	static detail(id) {
		return ApiClient.apiGet(`event/${id}`)
	}

	static delete(id) {
		return ApiClient.apiDelete(`event/${id}`)
	}
}