import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import router from './router'
import './registerServiceWorker'
import moment from 'moment'
import SocialSharing from 'vue-social-sharing'
import i18n from '@/middlewares/i18n'
import VueToast from 'vue-toast-notification'

import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

Vue.use(i18n);
Vue.use(VueResource)
Vue.use(SocialSharing)

Vue.filter('formatLongDate', (value) => {
  if (value) {
    return moment(String(value)).locale(localStorage.getItem('fwblog_lang')).format('LL')
  }
})

Vue.use(VueToast, {
  position: 'top-right'
})

new Vue({
	i18n,
  router,
  render: h => h(App)
}).$mount('#app')
