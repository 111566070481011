<template>
	<div class="login">
		<form ref="loginForm" novalidade @submit.prevent="login">
			<input type="email" name="email" placeholder="E-mail" v-model="email" required>
			<input type="password" name="password" placeholder="Password" v-model="password" required>
			<button type="submit">Login</button>
		</form>
	</div>
</template>
<script>
	import UserService from '@/services/user-service'
	import { EventBus } from '../event-bus'

	export default {
		name: 'login',
		data() {
			return {
				email: '',
				password: ''
			}
		},
		methods: {
			login() {
				UserService.login(this.email, this.password).then(resp => {
					EventBus.$emit('user:logged-in', resp)
					this.$router.push({ name: 'Home' })
				})
			}
		}
	}
</script>