import ApiClient from './api-client';

export default class UserService {
	static login(email, password) {
		console.log("login > ", email, " > ", password)
		return ApiClient.apiPost('login', { email, password });
	}

	static setUser(user) {
		localStorage.setItem('currentUser', JSON.stringify(user))
	}

	static getUser() {
		let user = localStorage.getItem('currentUser')
		if (user) {
			try {
				user = JSON.parse(user)
			} catch(e) {
				return null
			}
			return user
		}
		return null
	}

	static isAuthenticated() {
		return this.getUser() !== null && this.getUser() !== undefined
	}

	static isAuthorized(roles) {
		const user = this.getUser()
		return user && roles.indexOf(user.role) > -1
	}

	static logout() {
		localStorage.removeItem('currentUser')
	}
}