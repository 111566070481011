<template>
  <div class="events">
    <div class="info-container">
      <h1 class="main-title">
        {{ $t("EVENTS.TITLE") }}
      </h1>

      <p class="description">
        {{ $t("EVENTS.DESCRIPTION") }}
      </p>
    <Modal v-if="openModal" @close="openModal = false">
      <div slot="header" class="modal-inner-header">
        <h3>{{ $t("EVENTS.ADD") }}</h3>
        <span title="Close" class="close" @click="openModal = false">X</span>
      </div>

      <div slot="body">
        <form @submit.prevent="createEvent">
          <div>
            <label for="">{{ $t("EVENTS.FRM_TITLE") }} *</label>
            <input name="title" type="text" :placeholder="$t('EVENTS.FRM_TITLE')" v-model="event.title" required>
          </div>
          <div>
            <label for="">{{ $t("EVENTS.FRM_DESC") }} *</label>
            <textarea name="description" id="" cols="30" rows="3" :placeholder="$t('EVENTS.FRM_DESC')" v-model="event.description" required></textarea>
          </div>
          <div>
            <label for="">{{ $t("EVENTS.FRM_DATE") }} *</label>
            <input name="date" type="datetime-local" v-model="event.date" required>
          </div>
          <div>
            <label for="">{{ $t("EVENTS.FRM_ADDRESS") }} *</label>
            <input type="search" id="address" required :placeholder="$t('EVENTS.FRM_ADDRESS')">
          </div>
          <p>
            <small>{{ $t("EVENTS.FRM_MSG") }}</small>
          </p>

          <button type="submit">{{ $t("GLOBAL.SAVE") }}</button>
        </form>
      </div>
      <div slot="footer" style="display: none;"></div>
    </Modal>
    <a class="add-btn" href="javascript:void(0)" @click="openModal = true">+ {{ $t("EVENTS.ADD") }}</a>
    </div>

    <div class="map-container">
      <div id="map"></div>
    </div>


  </div>
</template>

<script>
import EventService from '@/services/event-service'
import UserService from '@/services/user-service'
import ShareBox from '@/components/ShareBox.vue'
import TagsList from '@/components/TagsList.vue'
import Modal from '@/components/Modal.vue'
import { EventBus } from '../event-bus'
import moment from "moment";

export default {
  name: 'Events',
  components: {
    ShareBox,
    TagsList,
    Modal
  },
  data() {
  	return {
      hasShare: navigator.share,
  		isAuthenticated: UserService.isAuthenticated(),
  		events: [],
      searchText: '',
      openModal: false,
      event: {
        title: "",
        description: "",
        date: null,
        address: "",
        latitude: null,
        longitude: null
      },
      map: null,
      layerGroup: null
  	}
  },
  watch: {
    '$route' (to, from) {
      // necessario para recarregar dados ao pressionar o back btn
      this.getData()
    },
    openModal(v) {
      if (v) {
        setTimeout(this.initAddressAutocomplete, 500);
      }
    }
  },
  mounted() {
    this.initMap();
    this.getData();
  },
  methods: {
    initMap() {
      this.map = L.map('map').setView([0, 0], 7);
        var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        //attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });
      OpenStreetMap_Mapnik.addTo(this.map);

      this.centerMapOnUser();
      //var marker = L.marker([-22.940650, -43.380760]).addTo(mymap);
      //marker.bindPopup("<b>Casa</b>.").openPopup();
    },
    centerMapOnUser() {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      const self = this;

      function success(pos) {
        var crd = pos.coords;
        self.map.setView(new L.LatLng(crd.latitude, crd.longitude), 7);
      }

      function error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    initAddressAutocomplete() {
      const self = this;
      const input = document.getElementById('address');
      const searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
          const place = places[0];
          self.event.address = place.formatted_address;
          self.event.latitude = place.geometry.location.lat();
          self.event.longitude = place.geometry.location.lng();
        }
      });
    },
    getData() {
      EventService.list(this.$route.query).then(resp => {
        this.events = resp

        const locale = window.navigator.userLanguage || window.navigator.language;

        const mks = []

        for (const x in this.events) {
          const event = this.events[x];
          const dateDesc = moment.utc(event.date).locale(locale).format('LLL');

          let popupContent = '<div>';
          popupContent += event.title + '<br>';
          popupContent += `<b>${this.$t("EVENTS.FRM_DATE")}: </b>${dateDesc}<br>`;
          if (event.description) {
            popupContent += event.description + '<br>';
          }
          popupContent += `${event.address}</div>`;

          const mk = new L.Marker([event.location.coordinates[1], event.location.coordinates[0]]).bindPopup(popupContent);
          mks.push(mk)
        }

        this.layerGroup = new L.layerGroup(mks);
        this.layerGroup.addTo(this.map)

      })
    },
    validateForm() {
      if (this.event.title && this.event.description && this.event.date && this.event.latitude && this.event.longitude) {
        return true;
      }
      return false;
    },
    createEvent() {
      if (this.validateForm()) {
        EventService.create(this.event).then(resp => {
          EventBus.$emit('toast', this.$t("EVENTS.SAVE_SUCCESS"));
          this.openModal = false
        });
      } else {
        EventBus.$emit('toast', this.$t("EVENTS.FIELDS_REQUIRED"));
      }
    },
    shareBlog(title, id) {
      if (navigator.share) {
        navigator.share({
          title: this.$t("EVENTS.SHARE_MSG"),
          text: title,
          url: `${window.location.origin}/event/${id}`,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }
  }
}
</script>

<style lang="scss">
  .events {
    height: 85%;

    .main-title {
      font-size: 3em;
      margin-top: 0;
      margin-bottom: 0;
    }

    .create-event-btn {
      min-width: 200px;
    }

    .info-container {
      height: 20%;
    }

    .map-container {
      height: 80%;
    }

    #map {
      width: 100%;
      min-height: 400px;
      height: 100%;
    }

    form {
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
      }

      input, textarea {
        width: 100%;
      }
    }

    .modal-inner-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span.close {
        cursor: pointer;
      }
    }

    .add-btn {
      margin-bottom: 10px;
    }

    p.description {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
</style>
