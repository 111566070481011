<template>
	<div>
		<h1>{{ survey.title }}</h1>
		<p>{{ survey.description }}</p>
		<p>Start date <strong>{{ survey.startDate | formatLongDate }}</strong></p>
		<p>Finish date <strong>{{ survey.finishDate | formatLongDate }}</strong></p>

		<form @submit.prevent="sendAnswer">
			<div class="questions">
				<div v-for="item in survey.questions" :key="item._id">
					<p>
						{{ item.text }}
					</p>

					<div v-if="item.answerType === 'text'" class="answer">
						<input type="text" placeholder="Answer" name="answer" required v-model="data.answer">
					</div>
					<div v-if="item.answerType === 'radio'" class="answer">
						<label class="input-label" :for="option._id" v-for="option in item.answerOptions" :key="option._id">
							{{ option.text }}
							<input :id="option._id" type="radio" :value="option.value" :name="item._id" required v-model="data.answer">
						</label>
					</div>
					<div v-if="item.answerType === 'checkbox'" class="answer">
						<label class="input-label" :for="option._id" v-for="option in item.answerOptions" :key="option._id">
							{{ option.text }}
							<input :id="option._id" type="checkbox" :value="option.value" :name="item._id" required v-model="data.answer">
						</label>
					</div>
					<div v-if="item.answerType === 'select'" class="answer">
						<select name="answer" id="" required v-model="data.answer">
							<option :value="option.value" v-for="option in item.answerOptions" :key="option._id">{{ option.text }}</option>
						</select>
					</div>
				</div>
			</div>

			<button type="submit">Send</button>
		</form>

	</div>
</template>
<script>
	import SurveyService from "@/services/survey-service"
	import { EventBus } from '../event-bus'

	export default {
		name: "Survey",
		data() {
			return {
				survey: {},
				data: {
					answer: null
				}
			}
		},
		mounted() {
			SurveyService.getBySlug(this.$route.params.slug).then(resp => {
				this.survey = resp
			})
		},
		methods: {
			sendAnswer() {
				SurveyService.answer(this.survey._id, this.data).then(() => {
					EventBus.$emit('toast', 'Thanks for your answer! We will publish the results soon!')
					setTimeout(() => {
						this.$router.push({ name: 'Home' })
					});
				}).catch(err => {
					if (err.status === 400) {
						EventBus.$emit('toast', err.body)
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.input-label {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 5px;
	}

	div.questions {
		display: flex;
		justify-content: center;
	}

	div.answer {
		display: flex;
		flex-direction: column;
	}
</style>