<template>
	<ul v-if="pages && pages.length" class="paginator">
		Page: <li :class="{'active': page.active}" :key="page.num" v-for="page in pages">
			<a href="javascript:void(0)" @click="navigate(page)">{{ page.num }}</a>
		</li>
	</ul>
</template>
<script>
	export default {
		name: 'Paginator',
		props: ['total', 'perPage'],
		data() {
			return {
				pages: []
			}
		},
		mounted() {
			this.buildPages();
		},
		methods: {
			buildPages() {
				const pages = this.$props.total / this.$props.perPage;
				for (let x = 1; x <= Math.ceil(pages); x +=1) {
					this.pages.push({num: x, active: x === 1 ? true : false});
				}
			},
			navigate(page) {
				this.pages.forEach(p => { p.active = false });
				page.active = true;
				this.$emit('navigate', page);
			}
		}
	}
</script>
<style lang="scss" scoped>
	.paginator {
		display: flex;
		flex-direction: row;

		li {
			margin-left: 5px;
		}

		li.active {
			font-weight: bolder;
			text-decoration: underline;
		}
	}
</style>