module.exports = Object.assign(
	{},
	require("./home.json"),
	require("./global.json"),
	require("./navbar.json"),
	require("./share.json"),
	require("./post.json"),
	require("./comment.json"),
	require("./events.json"),
	require("./about.json"),
	require("./crypto-donate.json"),
	require("./subscribe.json")
);
