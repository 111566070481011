<template>
	<div class="post-form">
		<h1>{{ title }}</h1>
		<form @submit.prevent="save">
			<input type="text" name="title" placeholder="Title" v-model="post.title" required>

			<vue-ckeditor
				class="ckeditor"
	      v-model="post.content"
	      :config="config"
	      @fileUploadRequest="onFileUploadRequest($event)"
	      @fileUploadResponse="onFileUploadResponse($event)" />

			<textarea name="preview" id="preview" cols="30" rows="10" v-model="post.preview" placeholder="Preview (text only - no HTML)" required></textarea>

			<input type="text" name="previewImage" placeholder="Preview Image" v-model="post.previewImage">

			<input type="text" name="tags" placeholder="Tags" v-model="post.tags">

			Images
			<span :key="image" v-for="image in post.images">
				{{ image }}
			</span>

			<select name="type" id="type" v-model="post.type">
				<option value="1">Post</option>
				<option value="2">Misc</option>
			</select>

			<button type="submit">Post</button>
		</form>
		<button v-if="post._id" @click="deletePost">Delete</button>
	</div>
</template>
<script>
	import BlogService from '@/services/blog-service'
	import UserService from '@/services/user-service'
	import VueCkeditor from 'vue-ckeditor2';

	export default {
		name: 'post-form',
		components: { VueCkeditor },
		data() {
			return {
				config: {
					extraAllowedContent: 'iframe[*]', //to allow iframes
					filebrowserImageUploadUrl: `${process.env.VUE_APP_API_URL}/api/upload`,
					height: '100vh'
				},
				post: {
					_id: '',
					title: '',
					content: '',
					preview: '',
					tags: '',
					images: [],
					previewImage: '',
					type: 1
				}
			}
		},
		computed: {
			title() {
				return this.$route.params.id ? 'Edit post' : 'Create a new post'
			}
		},
		mounted() {
			const id = this.$route.params.id;
			if (id) {
				BlogService.detail(id).then(resp => {
					this.post = resp
					if (this.post.tags) {
						this.post.tags = this.post.tags.join(', ')
					}
				})
			}
		},
		methods: {
			save() {
				if (this.post._id) {
					BlogService.update(this.post).then(resp => {
						this.redirHome()
					})
				} else {
					BlogService.create(this.post).then(resp => {
						this.redirHome()
					})
				}
			},
			onFileUploadRequest(evt) {
				const user = UserService.getUser();
				var xhr = evt.data.fileLoader.xhr;
		    xhr.setRequestHeader('Authorization', `Bearer ${user.token}`);
			},
			onFileUploadResponse(evt) {
				if (!Array.isArray(this.post.images)) {
					this.post.images = [];
				}

				// Prevent the default response handler.
		    evt.stop();

		    // Get XHR and response.
		    var data = evt.data,
		        xhr = data.fileLoader.xhr,
		        response = JSON.parse(xhr.responseText);

		    if ( response.error ) {
		        // An error occurred during upload.
		        data.message = response.error;
		        evt.cancel();
		    } else {
		        data.url = response.url;
		    }

				this.post.images.push(data.url)
			},
			deletePost() {
				BlogService.delete(this.post._id).then(() => {
					this.redirHome()
				})
			},
			redirHome() {
				this.$router.push({ name: 'Home' })
			}
		}
	}
</script>
<style lang="scss">
	.post-form form {
		display: flex;
		flex-direction: column;

		.ckeditor {
			margin-bottom: 5px;
		}
	}
</style>