<template>
	<p class="eth-address-container">
    <input ref="ethInput" type="text" :value="ethAddress"> <a @click="copyEthAddress" href="javascript:void(0)">
      <img src="/img/copy_icon.svg" alt="">
    </a>
  </p>
</template>
<script>
  export default {
    name: "EthCopy",
    data() {
      return {
        ethAddress: process.env.VUE_APP_ETH_ADDRESS
      }
    },
    methods: {
      copyEthAddress() {
        this.$refs.ethInput.select();
        document.execCommand("copy");
      }
    }
  }
</script>
<style lang="scss" scoped>
  .eth-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      max-height: 25px;
    }
  }
</style>