<template>
  <div class="home">
    <h1 class="main-title">
      {{ $t("GLOBAL.FILES") }}
    </h1>
    <div class="search-box">
      <input type="search" :placeholder="$t('GLOBAL.SEARCH') + '...'" v-model="searchText" @search="search">
      <button type="button" @click="search">{{ $t("GLOBAL.SEARCH") }}</button>
    </div>

    <FileForm
      v-if="isAuthenticated"
      @file:created="onFileCreated"
      :currentFile="currentFile" />

		<div v-for="(file, index) in files" :key="file._id" class="post-content">
      <router-link class="title" :to="'file/'+file.slug">
        <h2>{{ file.title }}</h2>
      </router-link>
      <small>{{ file.createdAt | formatLongDate }}</small>
			<p>
        {{ file.description }}
      </p>

      <ShareBox :url="getFileUrl(file.slug)" :title="file.title"></ShareBox>
      <!-- <router-link v-if="isAuthenticated" :to="'post-form/'+post._id">Edit</router-link> -->

      <button @click="editFile(file)" v-if="isAuthenticated">Edit</button>

    </div>
    <Paginator v-if="files && total" :per-page="10" :total="total" @navigate="onPageChange" />
  </div>
</template>

<script>
import FileService from '@/services/file-service'
import UserService from '@/services/user-service'
import ShareBox from '@/components/ShareBox.vue'
import Paginator from '@/components/Paginator.vue'
import FileForm from '@/components/FileForm.vue'
import { EventBus } from '../event-bus'

export default {
  name: 'Files',
  components: {
    ShareBox,
    Paginator,
    FileForm
  },
  data() {
  	return {
      hasShare: navigator.share,
  		isAuthenticated: UserService.isAuthenticated(),
  		files: [],
      searchText: '',
      total: 0,
      currentFile: null
  	}
  },
  watch: {
    '$route' (to, from) {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      const query = this.$route.query;
      if (page && page > 0) {
        query.page = page-1;
      }
      FileService.list(query).then(resp => {
        this.files = resp.files
        this.total = resp.total
      })
    },
    search() {
      FileService.list({ search: this.searchText }).then(resp => {
        this.files = resp.files
      })
    },
    shareBlog(title, slug) {
      if (navigator.share) {
        navigator.share({
          title: 'View this file on The Fireworks Blog',
          text: title,
          url: `${window.location.origin}/file/${slug}`,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    },
    getFileUrl(slug) {
      return `${window.location.origin}/file/${slug}`
    },
    onPageChange(page) {
      this.getData(page.num);
    },
    onFileCreated(file) {
      this.files.push(file)
    },
    editFile(file) {
      this.currentFile = file
    }
  }
}
</script>

<style lang="scss">

  .main-title {
    font-size: 3em;
  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .post-content {
      max-width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
      margin: 20px;
      padding: 10px;
      position: relative;
      margin-bottom: 50px;
      transition: all .2s ease-in-out;

      h2 {
        text-transform: capitalize;
      }
    }

    .post-content:hover {
      /*box-shadow: 0 5px 22px 0 rgba(0,0,0,.25);*/
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      margin-bottom: 54px;
    }

    .title {
      font-size: 1.5em;
    }

    img {
      max-width: 100% !important;
      height: auto !important;
    }

    .preview-image {
      display: flex;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .read-more {
      font-size: 1.2em;
      font-weight: bolder;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .search-box {
      margin-top: 10px;
      input {
        margin-right: 5px;
      }
    }
  }
</style>
