<template>
	<div>
		<h1>Surveys</h1>
		<p>
			This is our survey page. It is always good to get your oppinion about certain themes so we created the surveys page to capture information from our visitors. Below you can access the current open surveys. After their finish date, we will do a post about the theme and publish the obtained results.
		</p>
		<router-link class="title" :to="'survey/'+survey.slug" v-for="survey in surveys" :key="survey._id">
			<h2>{{ survey.title }}</h2>
		</router-link>
	</div>
</template>
<script>
	import SurveyService from "@/services/survey-service"

	export default {
		name: "Surveys",
		data() {
			return {
				surveys: []
			}
		},
		mounted() {
			SurveyService.getAll().then(resp => {
				this.surveys = resp
			})
		},
		methods: {

		}
	}
</script>
<style lang="scss" scoped>

</style>