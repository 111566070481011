<template>
	<div class="file-form">
		<form @submit.prevent="saveFile">
			<input type="text" name="title" placeholder="Title" v-model="file.title" required>
			<textarea name="description" placeholder="Description" cols="30" rows="5" v-model="file.description" required></textarea>
			<input type="file" name="fileInput" required @change="onFileChange">
			<input type="submit" value="Save">
		</form>
	</div>
</template>
<script>
	import FileService from '@/services/file-service'

	export default {
		name: 'FileForm',
		props: ["currentFile"],
		data() {
			return {
				file: {
					title: '',
					description: '',
					fileData: null
				}
			}
		},
		watch: {
			currentFile: {
				deep: true,
				handler(v) {
					this.file = this.currentFile
				}
			}
		},
		methods: {
			saveFile() {
				if (this.currentFile) {
					FileService.update(this.file).then(resp => {
						this.$emit("file:created", resp)
					})
				} else {
					FileService.create(this.file).then(resp => {
						this.$emit("file:updated", resp)
					})
				}
			},
			onFileChange(e) {
				const file = e.target.files[0];
		    const reader = new FileReader();
		    const self = this;
		    reader.onloadend = () => {
		      console.log(reader.result);
		      this.file.fileData = reader.result;
		    };
		    reader.readAsDataURL(file);
			}
		}
	}
</script>
<style lang="scss" scoped>
	.file-form {
		form {
			display: flex;
			flex-direction: column;
		}
	}
</style>