<template>
	<div class="comments">
		<h4>{{ $t("COMMENT.ADD") }}</h4>
		<form @submit.prevent="save" ref="commentForm" class="comment-form">
			<input type="text" name="author" v-model="comment.author" :placeholder="$t('COMMENT.AUTHOR')" required>
			<textarea name="comment" id="comment" cols="30" rows="6" :placeholder="$t('COMMENT.COMMENT')" required v-model="comment.comment"></textarea>
			<button type="submit">{{ $t("COMMENT.COMMENT") }}</button>
		</form>
		<h4 v-if="post.comments && post.comments.length">{{ $t("COMMENT.TITLE") }}</h4>
		<p v-else>{{ $t("COMMENT.NO_COMMENTS") }}</p>
		<div class="comment" v-for="comment in post.comments" :key="comment._id">
			<h5>{{ comment.author }} - {{ comment.createdAt | formatLongDate }}</h5>
			<p>
				{{ comment.comment }}
			</p>
		</div>
	</div>
</template>
<script>
	import BlogService from '@/services/blog-service'
	import { EventBus } from '../event-bus'

	export default {
		name: 'Comments',
		props: {
			post: {}
		},
		data() {
			return {
				comment: {
					author: '',
					comment: ''
				}
			}
		},
		mounted() {

		},
		methods: {
			save() {
				if (this.comment.author && this.comment.comment) {
					BlogService.comment(this.post._id, this.comment).then(resp => {
						this.post.comments = resp.comments
						this.clearComment()
						EventBus.$emit('toast', this.$t("COMMENT.MSG"))
					})
				}
			},
			clearComment() {
				this.comment.author = ''
				this.comment.comment = ''
			}
		}
	}
</script>
<style lang="scss">
	.comments {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		h5, p {
			margin-top: 7px;
			margin-bottom: 7px;
		}
	}

	.comment-form {
		display: flex;
		flex-direction: column;

		button {
			align-self: center;
		}
	}
</style>