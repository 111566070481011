import ApiClient from './api-client';
import qs from "query-string";

export default class BlogService {
	static create(post) {
		return ApiClient.apiPost('post', post);
	}

	static update(post) {
		return ApiClient.apiPut(`post/${post._id}`, post)
	}

	static list(query) {
		const q = {};
		if (query.tag) {
			q.tag = query.tag
		}

		if (query.search) {
			q.search = query.search
		}

		if (query.page) {
			q.page = query.page;
		}

		if (query.type) {
			q.type = query.type;
		}

		return ApiClient.apiGet(`post?${qs.stringify(q)}`)
	}

	static detail(id) {
		return ApiClient.apiGet(`post/${id}`)
	}

	static detailBySlug(slug) {
		return ApiClient.apiGet(`post/detail/${slug}`)
	}

	static delete(id) {
		return ApiClient.apiDelete(`post/${id}`)
	}

	static comment(postId, comment) {
		return ApiClient.apiPost(`post/${postId}/comment`, comment)
	}

	static subscribe(subs) {
		return ApiClient.apiPost('subscribe', subs)
	}

	static subscriptions() {
		return ApiClient.apiGet('subscriptions');
	}

	static deleteSubscription(id) {
		return ApiClient.apiDelete(`subscriptions/${id}`)
	}

	static related(id) {
		return ApiClient.apiGet(`post/${id}/related`)
	}
}